import React from 'react';
import PropTypes from 'prop-types';
import SmartSnippet from '../../algolia_search/smart_snippet';

function QuoteBioOrPublicPitch({ source }) {
  let attributeToDisplay;

  // If they have nothing at all, bail out
  if (
    !source.latest_quote
    && !source.bio
    && (!source.public_pitches || source.public_pitches.length === 0)
  ) {
    return null;
  }

  // If we’re viewing a search result with highlights, look for them
  if (source._highlightResult) {
    // If highlight is in `latest_quote`
    if (
      source._highlightResult.latest_quote
      && source._highlightResult.latest_quote.matchLevel !== 'none'
    ) {
      attributeToDisplay = 'latest_quote';

      // If highlight is in `bio`
    } else if (
      source._highlightResult.bio
      && source._highlightResult.bio.matchLevel !== 'none'
    ) {
      attributeToDisplay = 'bio';

      // If highlight is in any quote
    } else if (
      source._highlightResult.quotes
      && source._highlightResult.quotes.some(
        (q) => q.quote && q.quote.matchLevel !== 'none',
      )
    ) {
      const i = source._highlightResult.quotes.findIndex(
        (q) => q.quote && q.quote.matchLevel !== 'none',
      );
      attributeToDisplay = `quotes[${i}].quote`;

      // If highlight is in any public pitch
    } else if (
      source._highlightResult.public_pitches
      && source._highlightResult.public_pitches.some(
        (p) => p.public_pitch && p.public_pitch.matchLevel !== 'none',
      )
    ) {
      const i = source._highlightResult.public_pitches.findIndex(
        (p) => p.public_pitch && p.public_pitch.matchLevel !== 'none',
      );
      attributeToDisplay = `public_pitches[${i}].public_pitch`;
    }
  }

  // If none of the above matched, fall back
  if (!attributeToDisplay) {
    if (source.latest_quote) {
      attributeToDisplay = 'latest_quote';
    } else if (source.bio) {
      attributeToDisplay = 'bio';
    } else if (source.public_pitches && source.public_pitches.length > 0) {
      // By default, show the first pitch
      attributeToDisplay = 'public_pitches[0].public_pitch';
    }
  }

  return (
    <div className="font-size-12px mt-2">
      {(attributeToDisplay === 'latest_quote') && (<span className="fw-bold">LATEST: </span>)}
      <em className="text-secondary">
        <SmartSnippet attribute={attributeToDisplay} hit={source} />
      </em>
    </div>
  );
}

QuoteBioOrPublicPitch.propTypes = {
  source: PropTypes.instanceOf(Object).isRequired,
};

export default QuoteBioOrPublicPitch;
