import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select2Publication from '../select2_publication';
import Select2Company from '../select2_company';

function AudienceAndHiringOrgForm({
                                    current_user_admin: currentUserAdmin,
                                    audience,
                                    hiring_organization_errors: hiringOrganizationErrors,
                                    hiring_organization_id: hiringOrganizationId,
                                    hiring_organization_type: hiringOrganizationType,
                                    hiring_organization_name: hiringOrganizationName,
                                  }) {
  const [audienceValue, setAudienceValue] = useState(audience);
  const [hiringOrgId, setHiringOrgId] = useState(hiringOrganizationId);
  const [hiringOrgType, setHiringOrgType] = useState(hiringOrganizationType);
  const [hiringOrgName, setHiringOrgName] = useState(hiringOrganizationName);

  const hiringOrgErrorClass = hiringOrganizationErrors ? 'text-danger' : '';

  const handleAudienceChange = (changeEvent) => {
    const newValue = changeEvent.target.value;
    setAudienceValue(newValue);
    switch (newValue) {
      case 'reporters':
        setHiringOrgType('Publication');
        break;

      case 'pr_users':
        setHiringOrgType('Company');
        break;
      default:
        throw new Error('handleAudienceChange: Unsupported audience value;');
    }
    setHiringOrgId(null);
    setHiringOrgName(null);
  };

  const handleHiringOrgChange = (id, name) => {
    setHiringOrgId(id);
    setHiringOrgName(name);
  };

  return (
    <>
      <div className={`row mb-3 ${currentUserAdmin ? null : 'd-none'}`}>
        <label className="col-form-label col-md-2" htmlFor="job_posting_audience">Audience</label>
        <div className="col-md-8 pt-2">
          <input
            checked={audienceValue === 'pr_users'}
            id="job_posting_audience_pr_users"
            name="job_posting[audience]"
            type="radio"
            value="pr_users"
            onChange={handleAudienceChange}
          />
          &nbsp;
          <label className="me-2" htmlFor="job_posting_audience_pr_users">PR users</label>
          <input
            checked={audienceValue === 'reporters'}
            id="job_posting_audience_reporters"
            name="job_posting[audience]"
            type="radio"
            value="reporters"
            onChange={handleAudienceChange}
          />
          &nbsp;
          <label className="me-2" htmlFor="job_posting_audience_reporters">Reporters</label>
        </div>
      </div>

      <div className={`test-job-posting-hiring-organization row mb-3 required ${hiringOrgErrorClass}`}>
        <label className="col-form-label col-md-2" htmlFor="job_posting_hiring_organization">
          Hiring {hiringOrgType === 'Publication' ? 'media outlet' : hiringOrgType?.toLowerCase()}
        </label>
        <div className="col-md-10">
          <input
            id="job_posting_hiring_organization_id"
            name="job_posting[hiring_organization_id]"
            type="hidden"
            value={hiringOrgId}
          />
          <input
            id="job_posting_hiring_organization_type"
            name="job_posting[hiring_organization_type]"
            type="hidden"
            value={hiringOrgType}
          />
          <input
            id="hiring_organization_name"
            name="hiring_organization_name"
            type="hidden"
            value={hiringOrgName}
          />
          {audienceValue === 'reporters'
            && (
              <Select2Publication
                selectedPublication={{ id: hiringOrgId, name: hiringOrgName }}
                onChange={handleHiringOrgChange}
                placeholderText="What is the name of the media outlet hiring?"
              />
            )}
          {audienceValue === 'pr_users'
            && (
              <Select2Company
                selectedCompany={{ id: hiringOrgId, name: hiringOrgName }}
                onChange={handleHiringOrgChange}
                placeholderText="What is the name of the company hiring?"
              />
            )}
          {hiringOrganizationErrors
            && <div className="form-text text-danger">{hiringOrganizationErrors}</div>}
        </div>
      </div>
    </>
  );
}

AudienceAndHiringOrgForm.propTypes = {
  audience: PropTypes.string,
  current_user_admin: PropTypes.bool,
  hiring_organization_errors: PropTypes.string,
  hiring_organization_id: PropTypes.number,
  hiring_organization_name: PropTypes.string,
  hiring_organization_type: PropTypes.string,
};

AudienceAndHiringOrgForm.defaultProps = {
  audience: 'reporter',
  current_user_admin: false,
  hiring_organization_errors: null,
  hiring_organization_id: '',
  hiring_organization_name: '',
  hiring_organization_type: '',
};

export default AudienceAndHiringOrgForm;
