// https://documentation.onesignal.com/docs/web-sdk-reference
import OneSignal from 'react-onesignal';
import vlog from '../../vlog';
import globals from '../../globals';

async function pushSubscriptionChangeListener(event) {
  // NB: This is the format of event.current
  // {
  //   id: "4873e340-8406-42b3-979f-4c76df8f76e5", // NB: this becomes subscription_id
  //   optedIn: false, // NB: this becomes opted_in
  //   token: "https://fcm.googleapis.com/fcm/send/..."
  // }

  const REFUSAL_STORAGE_KEY = 'webPushRefusal';

  vlog.debug('Push subscription state changed', event.current);

  if (event.current.token) {
    const token = await window.supabaseToken();
    const userId = window.CURRENT_USER_JSON?.id;
    const v2UserId = await window.v2UserId();
    if (!token || !userId || !v2UserId) return;

    if (event.current.optedIn) {
      // User opted in: clear refusal data
      vlog.info('User has subscribed to notifications. Clearing refusal data.');
      localStorage.removeItem(REFUSAL_STORAGE_KEY);
    } else {
      // User opted out: pre-fill refusal data
      vlog.info('User has unsubscribed from notifications. Pre-filling refusal data.');
      const refusalData = {
        refusalCount: 1,
        lastRefusedAt: new Date().toISOString(),
      };
      localStorage.setItem(REFUSAL_STORAGE_KEY, JSON.stringify(refusalData));
      vlog.debug('Pre-filled refusal data:', refusalData);
    }

    const authenticatedHeadersHash = {
      apikey: window.SUPABASE_ANON_KEY,
      Authorization: `Bearer ${token}`,
    };

    // Post the subscription data to the server
    const subscriptionData = {
      data: {
        attributes: {
          migrate_user_id: userId,
          opted_in: event.current.optedIn,
          subscription_id: event.current.id,
          token: event.current.token,
        },
      },
    };

    try {
      const response = await fetch(`${window.V2_URL}/notify/api/users/${v2UserId}/web_push_subscriptions`, {
        method: 'POST',
        headers: new Headers({
          ...authenticatedHeadersHash,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(subscriptionData),
      });

      if (!response.ok) {
        const error = await response.json();
        vlog.error('Failed to update web push subscription:', error);
      } else {
        vlog.debug('Successfully updated web push subscription on server.');
      }
    } catch (error) {
      vlog.error('Error updating web push subscription:', error);
    }
  } else {
    vlog.warn('No token available in push subscription change event. Skipping server update.');
  }
}

function fireOneSignalLoginEvent(userId) {
  const oneSignalLoginEvent = new CustomEvent('qwotedOneSignalLoggedIn', {
    detail: { userId },
  });
  window.dispatchEvent(oneSignalLoginEvent);
  vlog.debug(`qwotedOneSignalLoggedIn event fired for userId: ${userId}`);
}

async function initializeOneSignal() {
  if (!window.ONESIGNAL_APP_ID) {
    vlog.debug('ONESIGNAL_APP_ID is not available. Initialization deferred.');
    return;
  }

  const allowedDomains = ['app.qwoted.com', 'app.qwoted-staging.tech', 'localhost'];
  if (!allowedDomains.includes(window.location.hostname)) {
    vlog.debug(
      `OneSignal: domain ${
        window.location.hostname} is not in [${allowedDomains.join(', ')}]. Aborting initialization.`,
    );
    return;
  }

  await OneSignal.init({
    persistNotification: false,
    autoResubscribe: true,
    allowLocalhostAsSecureOrigin: globals.BUSINESS_ENVIRONMENT === 'development',
    appId: window.ONESIGNAL_APP_ID,
    notifyButton: {
      enable: false,
    },
    welcomeNotification: { message: 'Never miss a beat with Qwoted notifications!' },
  });

  vlog.debug('OneSignal initialized.');

  const userId = window.CURRENT_USER_JSON?.id;
  if (userId) {
    await OneSignal.login(userId.toString());
    vlog.debug(`OneSignal logged in as userId: ${userId}`);
    fireOneSignalLoginEvent(userId);

    await OneSignal.User.PushSubscription.addEventListener('change', pushSubscriptionChangeListener);
  } else {
    vlog.debug('OneSignal not logged in.');
  }
}

// Ensure OneSignal is initialized on DOM load
document.addEventListener('DOMContentLoaded', initializeOneSignal);
