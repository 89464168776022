import React, { useState } from 'react';
import PropTypes from 'prop-types';
import vapi from '../javascript/frontend/api/vapi';

function ScoldBuddyDialog(props) {
  const {
    containerStyle, userId, codeOfConductURL,
  } = props;

  const [showAcceptenceContent, setShowAcceptenceContent] = useState(false);

  const agree = () => {
    setShowAcceptenceContent(true);
  };

  const accept = (event) => {
    if (event.target.value === 'y') {
      const userParams = {
        id: userId,
        attributes: { suspected_leaker_dialog_enabled: false },
      };

      vapi.updateUser(userParams)
        .then((response) => {
          if (response.status === 200) {
            window.location.reload();
          }
        });
    }
  };

  return (
    <div className="card" style={containerStyle}>
      <div className="card-body">
        <h3 className="card-title text-danger">
          <i className="fa-solid fa-circle-exclamation" /> We've temporarily restricted access to pitches
        </h3>
        <p className="card-text">
          <strong>
            Why is this happening?
          </strong>
        </p>
        <p className="card-text">
          We've noticed that you've unlocked multiple opportunities on Qwoted without submitting any pitches.
          To maintain the trust and quality of our community,
          it's important to respond directly to opportunities within Qwoted's platform.
        </p>
        <p className="card-text">
          <strong>
          Why is this important?
          </strong>
        </p>
        <p className="card-text">
          This helps uphold our Community Guidelines and&nbsp;
          <strong>creates a better experience for our media users who want to find their pitches in one place.</strong>
        </p>
        <p className="card-text">
          To lift this suspension, please agree to our <a href="https://www.qwoted.com/qwoted-community-guidelines/" target="_blank" rel="noreferrer">Community Guidelines</a>
        </p>
        <button type="button" className="btn btn-primary w-25" onClick={agree}>I agree</button>

        {showAcceptenceContent && (
          <>
            <p className="mt-3">
              Please re-read our <a href={codeOfConductURL} target="_blank" rel="noreferrer">Code of
              Conduct
            </a> and
              accept below. By doing so, you agree to pitch inside the platform and understand that repeated
              unlocks
              without pitches will result in being permanently locked out of your account.
            </p>

            <div className="mb-3">
              <label htmlFor="acceptDropdown">Do you accept Qwoted's Code of Conduct?</label>
              <select className="form-select" id="acceptDropdown" onChange={accept}>
                <option value="?">Please select</option>
                <option value="y">I accept Qwoted's code of Conduct</option>
                <option value="n">I do not accept Qwoted's Code of Conduct</option>
              </select>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ScoldBuddyDialog.propTypes = {
  containerStyle: PropTypes.oneOfType([PropTypes.object]),
  userId: PropTypes.number.isRequired,
  codeOfConductURL: PropTypes.string.isRequired,
  // TODO: Is there a plan for this prop or can we remove?
  // learnMoreURL: PropTypes.string,
};

ScoldBuddyDialog.defaultProps = {
  containerStyle: undefined,
  // learnMoreURL: undefined,
};

export default ScoldBuddyDialog;
