import PropTypes from 'prop-types';
import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox } from 'react-instantsearch-dom';

import ClearRefinementsButton from '../algolia_search/clear_refinements_button';

import Filters from './algolia/filters';
import InfiniteHits from './algolia/infinite_hits';
import MenuBar from '../directory/MenuBar';
import SmartStats from '../algolia_search/smart_stats';
import { PublicPitch, PublicPitchWithAdmin, PublicPitchSkeleton } from './public_pitch';

// PublicPitches component is the top-level component that aggregates and renders all public pitches.
function PublicPitches({
  algoliaAppId,
  algoliaSearchKey,
  canSendMessages,
  currentUserAdmin,
  currentUserSlug,
  defaultConversationStartPath,
  indexName,
  query,
}) {
  const [currentQuery, setCurrentQuery] = useState(query);
  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

  const publicPitchComponent = currentUserAdmin ? PublicPitchWithAdmin : PublicPitch;

  return (
    <React.StrictMode>
      <div className="ais-InstantSearch mt-3">
        <InstantSearch indexName={indexName} searchClient={searchClient}>
          <MenuBar view="pitches">
            {{
              searchForm: (
                <>
                  <SearchBox
                    autoFocus
                    className="d-flex me-3"
                    defaultRefinement={query}
                    onChange={(e) => setCurrentQuery(e.currentTarget.value)}
                    searchAsYouType={true}
                    translations={{
                      placeholder: 'Search',
                    }}
                  />
                  <ClearRefinementsButton
                    buttonText="Clear"
                    clearsQuery={true}
                    bootstrapClasses="px-3 btn btn-sm btn-outline-info text-nowrap"
                  />
                </>
              ),
            }}
          </MenuBar>
          <div>
            <div className="row mb-2">
              <div className="col pe-4 text-end">
                <SmartStats />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3 pr-0">
                <Filters />
              </div>
              <div className="col">
                <div className="alert alert-light-purple" role="alert">
                  <span className="header">
                    <i className="fa-regular fa-circle-info" style={{ paddingRight: '8px' }} />
                    &nbsp;Story Ideas gives you a quick rundown on what experts are talking about.
                  </span>
                  <br />
                  If a topic catches your attention and sparks an idea for your next story, click Start a Conversation
                  to get valuable commentary directly from the expert.
                </div>
                <div className="row overflow-hidden">
                  <InfiniteHits
                    PublicPitch={publicPitchComponent}
                    PublicPitchSkeleton={PublicPitchSkeleton}
                    algoliaAppId={algoliaAppId}
                    algoliaSearchKey={algoliaSearchKey}
                    canSendMessages={canSendMessages}
                    currentUserSlug={currentUserSlug}
                    defaultConversationStartPath={defaultConversationStartPath}
                    query={currentQuery}
                  />
                </div>
              </div>
            </div>
          </div>
        </InstantSearch>
      </div>
    </React.StrictMode>
  );
}

PublicPitches.propTypes = {
  // Algolia configuration and index name
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  query: PropTypes.string,

  // Current user parameters
  canSendMessages: PropTypes.bool.isRequired,
  currentUserAdmin: PropTypes.bool.isRequired,
  currentUserSlug: PropTypes.string,

  // General parameters
  defaultConversationStartPath: PropTypes.string.isRequired,
};

PublicPitches.defaultProps = {
  currentUserSlug: null,
  query: null,
};

export default PublicPitches;
