import React, {
  useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Input, Popover, PopoverBody,
} from 'reactstrap';
import vlog from '../../javascript/vlog';
import SearchRequests from './search_requests';

function ReporterRequestSearch({ userId }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [error, setError] = useState(false);
  const [passedHealthCheck, setPassedHealthCheck] = useState(false);
  const [supabaseToken, setSupabaseToken] = useState(null);
  const [v2UserProfileId, setV2UserProfileId] = useState(null);

  // Use this as the anchor id for the popover
  const inputId = 'reporterRequestSearchInput';

  const handleSearchQueryChange = (e) => {
    const val = e.target.value;
    setSearchQuery(val);
    setShowOverlay(val.trim().length > 0);
  };

  const handleFocus = () => {
    if (searchQuery.trim().length > 0) {
      setShowOverlay(true);
    }
  };

  useEffect(() => {
    const v2HealthCheck = async () => {
      const passed = await window.v2HealthCheck();
      if (passed) {
        setPassedHealthCheck(true);
      } else {
        vlog.debug('Component not ready: V2 health check did not pass.');
      }
    };
    v2HealthCheck();
  }, []);

  useEffect(() => {
    const checkComponentReady = async () => {
      const token = await window.supabaseToken();
      if (token) {
        setSupabaseToken(token);
      } else {
        vlog.debug('Component not ready: token not found.');
      }
    };
    checkComponentReady();
  }, []);

  const componentReady = useCallback(
    () => passedHealthCheck && supabaseToken != null && v2UserProfileId != null,
    [passedHealthCheck, supabaseToken, v2UserProfileId],
  );

  useEffect(() => {
    const fetchV2identifiers = async () => {
      try {
        const { data, error: fetchError } = await window.supabaseClient
          .from('user_profiles')
          .select('profile_id, account_user_id')
          .eq('user_migrate_id', userId)
          .single();

        if (fetchError) {
          vlog.error('Error fetching user profile:', fetchError);
          setError(true);
          return null;
        }

        if (data?.profile_id && data?.account_user_id) {
          setV2UserProfileId(data.profile_id);
          return true;
        }
        vlog.error('V2 IDs not found!');
        setError(true);
        return null;
      } catch (fetchError) {
        vlog.error('Error fetching V2 IDs', fetchError);
        setError(true);
        return null;
      }
    };

    fetchV2identifiers();
  }, [userId]);

  // --- Turbolinks Cleanup Logic ---
  useEffect(() => {
    const handleTurbolinksVisit = () => {
      // Hide the popover, clear the search, or any other reset
      setShowOverlay(false);
      setSearchQuery('');
    };

    // Depending on which event you want to listen for:
    // turbolinks:visit, turbolinks:before-visit, etc.
    document.addEventListener('turbolinks:visit', handleTurbolinksVisit);

    // Clean up on unmount
    return () => {
      document.removeEventListener('turbolinks:visit', handleTurbolinksVisit);
    };
  }, []);

  if (error) {
    return null;
  }
  if (!componentReady()) {
    return null;
  }

  return (
    <div>
      <div className="mb-2" style={{ maxWidth: '400px' }}>
        <Input
          id={inputId}
          type="search"
          placeholder="Search requests..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
          onFocus={handleFocus}
          style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
        />
      </div>

      <Popover
        popperClassName="mw-100"
        placement="bottom-start"
        isOpen={showOverlay}
        target={inputId}
        toggle={() => setShowOverlay(!showOverlay)}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4], // horizontal, vertical offset
            },
          },
        ]}
        hideArrow
        style={{
          border: '2px solid #bbb',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
          borderRadius: '0.25rem',
          maxHeight: '500px',
          overflowY: 'auto',
          // Make it responsive: fill up to 100% of its container, but never exceed 600px
          width: '100%',
          maxWidth: '600px',
        }}
      >
        <PopoverBody className="p-0">
          <SearchRequests
            searchQuery={searchQuery}
            supabaseToken={supabaseToken}
            v2UserProfileId={v2UserProfileId}
          />
        </PopoverBody>
      </Popover>
    </div>
  );
}

ReporterRequestSearch.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default ReporterRequestSearch;
