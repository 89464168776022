import React from 'react';
import PropTypes from 'prop-types';
import Select2Ajax from './select2_ajax';
import Select2AddPublicationModal from './select2_add_publication_modal';
import vahoy from '../javascript/vahoy';
import gaTracking from '../javascript/ga_tracking';

class Select2Publication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  processSelect2Result = (response) => {
    const results = (response.data || []).map((result) => {
      const r = { ...result };
      r.text = result.attributes.name;
      return r;
    });

    return {
      results,
      pagination: {
        more: !!(response.links || {}).next,
      },
    };
  };

  handleOnChange = (e) => {
    const {
      onChange,
      pageName,
      publicationIdSelector,
      publicationNameSelector,
    } = this.props;
    const publicationId = jQuery(e.target).val();
    const publicationName = jQuery(e.target).find('option:selected').text();

    onChange && onChange(publicationId, publicationName);

    if (publicationIdSelector) {
      jQuery(publicationIdSelector).val(publicationId);
    }

    if (publicationNameSelector) {
      if (publicationId === 'new') {
        jQuery(publicationNameSelector).val(publicationName);
      } else if (!publicationId) {
        jQuery(publicationNameSelector).val('');
      }
    }

    gaTracking.trackSignUp(pageName, 'selectPublicationFromPublicationSelect2', publicationName, publicationId);
    vahoy.track('Select2Publication#selectPublicationFromPublicationSelect2');
  };

  selectPublication = (id, name, publication) => {
    if (name) {
      const { $select2 } = this;
      const { onChange, pageName } = this.props;

      if ($select2) {
        const option = new Option(name, id, true, true);

        if (publication) {
          // NOTE: Any better way?
          option.attributes.image_cloudinary_url = publication.attributes.image_cloudinary_url;
        }

        $select2.append(option).trigger('change');
      }

      onChange && onChange(id, name);

      gaTracking.trackSignUp(pageName, 'selectPublicationFromPublicationModal', name, id);
      vahoy.track('Select2Publication#selectPublicationFromPublicationModal');
    }

    this.toggleAddPublicationModal();
  };

  onAppendedBtnClick = ($select2) => {
    this.toggleAddPublicationModal();
    this.$select2 = $select2;
  };

  onSearching = (params) => {
    this.setState({ searchingTerm: params.term });

    return 'Searching…';
  };

  templateResult = (option) => {
    const imageUrl = (option.attributes || {}).image_cloudinary_url;
    let { text } = option;
    const term = this.state.searchingTerm;

    if (term && text) {
      const regexp = new RegExp(term, 'gi');
      text = text.replace(regexp, (match) => `<span class="matched-text">${match}</span>`);
    }

    if (!imageUrl) {
      return `<span class="option"><span class="option-image"></span><span class="option-name">${text}</span></span>`;
    }

    const ob = `<span class="option"><span class="option-image"><img src="${imageUrl}" alt="" /></span><span class="option-name">${text}</span></span>`;

    return ob;
  };

  templateSelection = (option) => {
    let imageUrl = (option.attributes || {}).image_cloudinary_url;

    // This is for option added via modal.
    if (!imageUrl && option.element && option.element.attributes) {
      imageUrl = option.element.attributes.image_cloudinary_url;
    }

    if (imageUrl) {
      return `<span class="selected-option"><span class="option-image"><img src="${imageUrl}" alt="" /></span><span class="option-name">${option.text}</span></span>`;
    }
    return `<span class="selected-option"><span class="option-name">${option.text}</span></span>`;
  };

  toggleAddPublicationModal = () => {
    this.setState((prevState) => ({ showAddPublicationModal: !prevState.showAddPublicationModal }));
  };

  render() {
    const {
      className, selectedPublication, placeholderText,
    } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    const ajaxUrl = this.props.ajaxUrl || '/api/internal/jsonapi/publications';
    const { showAddPublicationModal, searchingTerm } = this.state;

    // Select2Ajax expects it in this form
    const selectedOption = selectedPublication && {
      value: selectedPublication?.id,
      label: selectedPublication?.name,
    };

    return (
      <div>
        <Select2Ajax
          ajaxProcessResults={this.processSelect2Result}
          ajaxUrl={ajaxUrl}
          appendedBtnLabel="Don't see your media outlet?"
          className={className || 'form-select'}
          existingOnly={true}
          onAppendedBtnClick={this.onAppendedBtnClick}
          onChange={this.handleOnChange}
          onSearching={this.onSearching}
          placeholderText={placeholderText}
          selectedOption={selectedOption}
          templateResult={this.templateResult}
          templateSelection={this.templateSelection}
        />
        <Select2AddPublicationModal
          labelText="Name of media outlet"
          modalTitle="Add new media outlet"
          onHide={this.toggleAddPublicationModal}
          onSelect={this.selectPublication}
          placeHolderText="New media outlet"
          searchingTerm={searchingTerm}
          showModal={showAddPublicationModal}
        />
      </div>
    );
  }
}

Select2Publication.propTypes = {
  ajaxUrl: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  pageName: PropTypes.string,
  placeholderText: PropTypes.string,
  publicationIdSelector: PropTypes.string,
  publicationNameSelector: PropTypes.string,
  selectedPublication: PropTypes.instanceOf(Object), // EXAMPLE: {id: 1, name: "Magnify Money"}
};

Select2Publication.defaultProps = {
  ajaxUrl: undefined,
  className: undefined,
  onChange: undefined,
  pageName: undefined,
  placeholderText: '',
  publicationIdSelector: undefined,
  publicationNameSelector: undefined,
  selectedPublication: undefined,
};

export default Select2Publication;
