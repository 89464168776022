import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import vlog from '../../javascript/vlog';

function SearchRequestPitch({ pitchId, pitchHtml }) {
  const [pitch, setPitch] = useState(null);

  useEffect(() => {
    async function fetchPitch() {
      try {
        const { data, error } = await window.supabaseClient
          .from('pitches')
          .select('*')
          .eq('id', pitchId)
          .maybeSingle();

        if (error) {
          vlog.error('Error fetching pitch:', error);
          return;
        }

        if (data) {
          setPitch(data);
        }
      } catch (err) {
        vlog.error('Error fetching pitch:', err);
      }
    }

    if (pitchId) {
      fetchPitch();
    }
  }, [pitchId]);

  if (!pitch) {
    return <div className="smaller text-muted">Loading pitch info...</div>;
  }

  const promoterName = pitch.profile_name;
  const promoterImageUrl = pitch.profile_image_url || '';
  const hasAttachments = (pitch.num_pitch_attachments || 0) > 0;
  const sanitizedHtml = DOMPurify.sanitize(pitchHtml);

  return (
    <div className="mb-1 d-flex align-items-start">
      <img
        src={promoterImageUrl}
        alt={promoterName}
        className="rounded-circle"
        style={{
          width: '25px',
          height: '25px',
          objectFit: 'cover',
          marginRight: '6px',
        }}
        onError={(e) => {
          e.currentTarget.style.display = 'none';
        }}
      />

      <div>
        {hasAttachments && (
          <span>
            <i className="fas fa-paperclip me-2" aria-hidden="true" />
          </span>
        )}
        <em>{promoterName}: </em>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
      </div>
    </div>
  );
}

SearchRequestPitch.propTypes = {
  pitchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pitchHtml: PropTypes.string.isRequired,
};

export default SearchRequestPitch;
