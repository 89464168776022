import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ClipboardJS from 'clipboard';
import { Tooltip } from 'bootstrap';
import vahoy from '../javascript/vahoy';

function CopyText({
                    text, tooltipText, buttonText, uniqueId, successCallback, ahoyTrackText, ahoyObjectGid,
                  }) {
  const clipboardRef = useRef(null);

  useEffect(() => {
    clipboardRef.current = new ClipboardJS(`#${uniqueId}`);

    clipboardRef.current.on('success', () => {
      const outerEl = document.getElementById(uniqueId);
      const outerTooltip = Tooltip.getOrCreateInstance(outerEl);

      if (ahoyTrackText) {
        const options = { text };
        if (ahoyObjectGid) {
          options.object_gid = ahoyObjectGid;
        }

        vahoy.track(ahoyTrackText, options);
      }

      if (typeof successCallback === 'function') {
        successCallback();
      }

      outerTooltip.show();
      setTimeout(() => {
        const el = document.getElementById(uniqueId);
        const tooltip = Tooltip.getOrCreateInstance(el);
        if (tooltip) {
          tooltip.hide();
        }
      }, 1000);
    });

    clipboardRef.current.on('error', () => {
    });

    return function cleanup() {
      clipboardRef.current && clipboardRef.current.destroy();
    };
  });

  return (
    <span className="position-relative">
      <button
        type="button"
        id={uniqueId}
        className="d-inline btn btn-link pt-0 ps-2 pe-0 me-0"
        data-bs-placement="top"
        data-bs-toggle="tooltip"
        data-bs-trigger="manual"
        data-clipboard-text={text}
        title={tooltipText}
      >
        <i className="fa-regular fa-copy font-size-14px" alt="Copy to clipboard" />
        {buttonText && ' '}
        {buttonText}
      </button>
    </span>
  );
}

CopyText.propTypes = {
  buttonText: PropTypes.string,
  successCallback: PropTypes.func,
  text: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  uniqueId: PropTypes.string.isRequired,
  ahoyTrackText: PropTypes.string,
  ahoyObjectGid: PropTypes.string,
};

CopyText.defaultProps = {
  buttonText: undefined,
  successCallback: undefined,
  ahoyTrackText: undefined,
  ahoyObjectGid: undefined,
};

export default CopyText;
