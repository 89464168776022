import React from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../../javascript/vahoy';
import SnippetHtmlEntities from '../../algolia_search/snippet_html_entities';

function PressReleaseHit({ hit, currentQuery }) {
  const trackClicks = () => {
    vahoy.track('PressRelease/PressReleaseCard#clickPressReleaseLink');
  };
  const maxDescriptionLength = 130;
  return (
    <div key={hit.id} className="h-100 card mb-3 ms-0 me-0 d-flex headline-card">
      <div style={{ background: '#d9ba7a' }} className="card-header text-black text-center fw-bold">
        <a className="text-black" title="Press Releases" href="/press_releases">Press Releases</a>
      </div>
      <div className="card-body position-relative flex-grow-1 d-flex flex-column justify-content-between">
        <div className="d-flex flex-column">
          <a href={hit.press_release_path} onClick={trackClicks} className="text-decoration-none pb-0 mb-2">
            <h2 style={{ fontSize: '1rem' }} className="card-title pb-0 m-0 fw-bold">
              {hit.title}
            </h2>
          </a>

          <div className="mb-1 mt-0 w-100">
            {hit.first_image_url && (
              <img
                src={hit.first_image_url}
                alt=""
                className="me-2"
                style={{ float: 'left', objectFit: 'cover' }}
                width="125px"
                height="125px"
                onError={
                  (event) => {
                    // eslint-disable-next-line no-param-reassign
                    event.target.style.display = 'none';
                  }
                }
              />
            )}
          </div>
          <div className="w-100 mt-2">
            {hit.content && (
              <p className="m-0 lh-sm" style={{ fontSize: '.9em' }}>
                <SnippetHtmlEntities attribute="content" hit={hit} truncateLength={maxDescriptionLength} />
              </p>
            )}
          </div>
        </div>

        <div className="mb-2">
          <div className="col mt-0">
            <a
              href={hit.press_release_path}
              className="btn btn-primary btn-large"
              style={{ marginBottom: '12px' }}
            >
             <small> View Press Release </small>
            </a>
          </div>
          <a className="my-2 text-decoration-underline font-size-14px fw-bold pb-2" href={`/press_releases?query=${currentQuery}`}>{`View ${currentQuery === '' ? 'all' : 'similar'} Press Releases`}</a>
        </div>
      </div>
    </div>
  );
}

PressReleaseHit.propTypes = {
  hit: PropTypes.instanceOf(Object).isRequired,
  currentQuery: PropTypes.string,
};

PressReleaseHit.defaultProps = {
  currentQuery: '',
};

export default PressReleaseHit;
