import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import SearchRequest from './search_request';

function SearchRequests({
                          searchQuery,
                          selectedSearchRequestId,
                          setSelectedSearchRequestId,
                          supabaseToken,
                          v2UserProfileId,
                        }) {
  const [searchedRequests, setSearchedRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const debounceTimer = useRef(null);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      // Reset any ongoing request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      const trimmedQuery = searchQuery.trim();
      if (!trimmedQuery) {
        // User cleared input: reset results, stop loading, reset hasSearched
        setSearchedRequests([]);
        setIsLoading(false);
        setHasSearched(false);
        return;
      }

      // Begin the search
      setIsLoading(true);
      setHasSearched(true);

      const encodedQuery = encodeURIComponent(trimmedQuery);
      const query = `${window.V2_URL}/interact/api/media_request_searches?filter[query]=${encodedQuery}&filter[profile_id]=${v2UserProfileId}&limit=9999`;

      const authenticatedHeadersHash = {
        apikey: window.SUPABASE_ANON_KEY,
        Authorization: `Bearer ${supabaseToken}`,
      };

      fetch(query, {
        method: 'GET',
        signal: abortControllerRef.current.signal,
        headers: new Headers({
          ...authenticatedHeadersHash,
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((responseJson) => {
          if (responseJson) {
            setSearchedRequests(responseJson.data);
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            // Handle/log error if desired
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 300);

    return () => {
      clearTimeout(debounceTimer.current);
      abortControllerRef.current.abort();
    };
  }, [searchQuery, supabaseToken, v2UserProfileId]);

  const handleRequestClick = (requestId) => {
    if (setSelectedSearchRequestId) {
      setSelectedSearchRequestId(requestId);
    }
  };

  return (
    <ul className="list-group" style={{ border: 'none' }}>
      {/** Show spinner if we’ve started searching and are still loading */}
      {hasSearched && isLoading && (
        <li className="list-group-item text-center">
          <Spinner type="border" color="primary" />
          <span className="ms-2">Searching...</span>
        </li>
      )}

      {/** Show results if we've searched, not loading, and have results */}
      {hasSearched && !isLoading && searchedRequests.map((request) => (
        <SearchRequest
          handleClick={handleRequestClick}
          hits={request.attributes.hits}
          key={request.id}
          requestId={request.id}
          searchQuery={searchQuery}
          selectedRequestId={selectedSearchRequestId}
          supabaseToken={supabaseToken}
          timestamp={request.attributes.updated_at}
        />
      ))}

      {/** If we've searched, not loading, and found no results */}
      {hasSearched && !isLoading && searchedRequests.length === 0 && (
        <li className="list-group-item">No matches found.</li>
      )}
    </ul>
  );
}

SearchRequests.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  selectedSearchRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedSearchRequestId: PropTypes.func,
  supabaseToken: PropTypes.string,
  v2UserProfileId: PropTypes.number.isRequired,
};

SearchRequests.defaultProps = {
  supabaseToken: null,
  setSelectedSearchRequestId: null,
  selectedSearchRequestId: null,
};

export default SearchRequests;
