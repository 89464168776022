import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import SearchRequestPitch from './search_request_pitch';
import vlog from '../../javascript/vlog';

function SearchRequest({
                         hits, requestId, supabaseToken, selectedRequestId, timestamp,
                       }) {
  const [request, setRequest] = useState(null);

  // Build the V1 path to the reporter request show page
  const requestpath = request && `/users/${window.CURRENT_USER_JSON.id}/reporter_requests/${request.migrate_id}`;

  useEffect(() => {
    async function fetchRequest() {
      try {
        const { data, error } = await window.supabaseClient
          .from('requests')
          .select('*')
          .eq('id', requestId)
          .maybeSingle();

        if (error) {
          vlog.error('Error fetching request:', error);
          return;
        }

        if (data) {
          setRequest(data);
        }
      } catch (err) {
        vlog.error('Error fetching request:', err);
      }
    }

    if (requestId) {
      fetchRequest();
    }
  }, [requestId]);

  const isSelected = requestId === selectedRequestId ? 'active' : '';

  // Separate pitch hits from request hits
  const pitchHits = hits.filter((hit) => hit.type === 'pitch');
  const requestHits = hits.filter((hit) => hit.type === 'request');

  // Use the first request snippet as the "title" snippet if it exists
  const requestHitSnippet = requestHits.length > 0 ? requestHits[0].content : null;

  function sanitizeMarkup(html) {
    return { __html: DOMPurify.sanitize(html) };
  }

  function formatDate(date) {
    const options = {
      weekday: 'short', month: 'short', day: 'numeric', year: 'numeric',
    };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  const formattedTimestamp = request && formatDate(timestamp);

  return (
    <li className={`list-group-item list-group-item-action ${isSelected} text-start`} style={{ borderRadius: '0px' }}>
      {request && (
        <a href={requestpath} style={{ display: 'block', textDecoration: 'none', color: 'inherit' }}>
          <h5 className="mb-1">
            <div className="link-primary">{request.title}</div>
            <div className="font-size-10px text-muted">{formattedTimestamp}</div>
          </h5>

          {requestHitSnippet && (
            <div
              className="mb-1"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={sanitizeMarkup(requestHitSnippet)}
            />
          )}

          {pitchHits.map((hit) => (
            <SearchRequestPitch
              key={hit.id}
              pitchId={hit.id}
              pitchHtml={hit.content}
              supabaseToken={supabaseToken}
            />
          ))}
        </a>
      )}
    </li>
  );
}

SearchRequest.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    content: PropTypes.string,
  })).isRequired,
  requestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  supabaseToken: PropTypes.string,
  selectedRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timestamp: PropTypes.string,
};

SearchRequest.defaultProps = {
  supabaseToken: null,
  selectedRequestId: null,
  timestamp: null,
};

export default SearchRequest;
