import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import ReporterSearch from './reporter_search';

function TopLevelSearch({
                          algoliaAppId, algoliaSearchKey, isAdmin, indexName, query, underAudit,
                        }) {
  return (
    <ErrorBoundary>
      <ReporterSearch
        algoliaAppId={algoliaAppId}
        algoliaSearchKey={algoliaSearchKey}
        isAdmin={isAdmin}
        indexName={indexName}
        query={query}
        underAudit={underAudit}
      />
    </ErrorBoundary>
  );
}

TopLevelSearch.propTypes = {
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  indexName: PropTypes.string.isRequired,
  query: PropTypes.string,
  underAudit: PropTypes.bool,
};

TopLevelSearch.defaultProps = {
  query: null,
  underAudit: false,
};

export default TopLevelSearch;
